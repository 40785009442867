import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import requestHandler from "./httpClient";

export const fetchCategory = createAsyncThunk("fetchCategory", async () => {
    try {
        const res = await requestHandler("category/categoryList", "post", "");
        return res;
    }
    catch (error) {
        throw error;
    }
});

const categorySlice = createSlice({
    name: "getCategory",
    initialState: {
        categoryLoading: false,
        categoryData: [],
        categoryError: false
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCategory.pending, (state, action) => {
            state.categoryLoading = true;
            state.categoryError = false;
        })
        builder.addCase(fetchCategory.fulfilled, (state, action) => {
            state.categoryLoading = false;
            state.categoryError = false;
            state.categoryData = action.payload?.data?.data;
        })
        builder.addCase(fetchCategory.rejected, (state, action) => {
            state.categoryLoading = false;
            state.categoryError = true;
        })
    }
});

export default categorySlice.reducer;
