import Link from "next/link";
import React from "react";
import { addNotification } from "../Notification";

const BuyNftModal = ({
  showModal,
  setShowModal,
  artifactData,
  buyNftHandler,
  terms,
  setTerms
}) => {

  return (
    <div className={showModal ? "modal fade show block" : "modal fade"} >
      <div className="modal-dialog max-w-2xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="buyNowModalLabel">
              Complete Checkout
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setShowModal(false);
                setTerms(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                className="fill-jacarta-700 h-6 w-6 dark:fill-white"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
              </svg>
            </button>
          </div>

          <div className="modal-body p-6">
            <div className="mb-2 flex items-center justify-between">
              <span className="font-display text-jacarta-700 text-sm font-semibold dark:text-white">
                Item
              </span>
              <span className="font-display text-jacarta-700 text-sm font-semibold dark:text-white">
                Subtotal
              </span>
            </div>

            <div className="dark:border-jacarta-600 border-jacarta-100 relative flex items-center border-t border-b py-4">
              <figure className="mr-5 self-start">
                <img
                  width={150}
                  height={150}
                  src={`${process.env.NEXT_PUBLIC_AWS_URL}/${artifactData?.image}`}
                  alt={artifactData?.name}
                  className="rounded-2lg"
                  loading="lazy"
                />
              </figure>

              <div>
                <h3 className="font-display text-jacarta-700 mb-1 text-base font-semibold dark:text-white">
                  {artifactData?.name}
                </h3>
                <div className="flex flex-wrap items-center">
                  <span className="dark:text-jacarta-300 text-jacarta-500 mr-1 block text-sm">
                    Creator Earnings: {artifactData?.collection?.royalty}%
                  </span>
                </div>
              </div>
            </div>
            <div className="dark:border-jacarta-600 border-jacarta-100 mb-2 flex items-center justify-between border-b py-2.5">
              <span className="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white">
                Total
              </span>
              <div className="ml-auto">
                <span className="flex items-center whitespace-nowrap">
                  <span className="text-green font-medium tracking-tight">
                    {artifactData?.price} {artifactData?.collection?.network?.symbol?.toUpperCase()}
                  </span>
                </span>
              </div>
            </div>
            <div className="mt-4 flex items-center space-x-2">
              <input
                type="checkbox"
                id="buyNowTerms"
                className="checked:bg-accent dark:bg-jacarta-600 text-accent border-jacarta-200 focus:ring-accent/20 dark:border-jacarta-500 h-5 w-5 self-start rounded focus:ring-offset-0"
                checked={terms}
                onChange={() => { setTerms(!terms) }}
              />
              <label
                htmlFor="buyNowTerms"
                className="dark:text-jacarta-200 text-sm"
              >
                By checking this box, I agree to {"Bitdenex's"}{" "}
                <Link href="" className="text-accent">
                  Terms of Service
                </Link>
              </label>
            </div>
          </div>
          <div className="modal-footer">
            <div className="flex items-center justify-center space-x-4">
              <button
                type="button"
                className="bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
                onClick={(e) => {
                  e.preventDefault();
                  if (terms) {
                    buyNftHandler(artifactData?.tokenId, artifactData?.collection?.walletAddress, artifactData?.price, artifactData?.id);
                  }
                  else {
                    addNotification({
                      title: "Alert",
                      message: "Please select the terms and conditions",
                      type: "danger",
                    });
                  }
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyNftModal;
