import React, { useState } from "react";
import Link from "next/link";
import "tippy.js/dist/tippy.css";
import MintModal from "../modal/mintModal";

const CategoryItemProfile = ({
  nftData,
  setLoading,
  getNfthandler
}) => {
  const [showModal, setShowModal] = useState(false);
  const [nftDetail, setNftDetail] = useState([]);

  return (
    <>
      {
        <div className="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-4">
          {nftData?.map((item, index) => {
            return (
              <article key={index}>
                <div className="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2.5xl block border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg">
                  <figure className="relative">
                    <Link href={`/artifact/${item?.id}`}>
                      <img
                        width={230}
                        height={230}
                        src={`${process.env.NEXT_PUBLIC_AWS_URL}/${item?.image}`}
                        alt={item.name}
                        className="w-full h-[230px] rounded-[0.625rem] object-cover"
                      />
                    </Link>
                  </figure>
                  <div className="mt-7 flex items-center justify-between">
                    <Link href={`/artifacts/${item?.id}}`}>
                      <span className="font-display text-jacarta-700 hover:text-accent text-base dark:text-white">
                        {item?.name}
                      </span>
                    </Link>
                  </div>
                  <div className="mt-2 text-sm">
                    <span className="dark:text-jacarta-200 text-jacarta-700 mr-1">
                      {item?.price} {item?.collection?.network?.name?.toUpperCase()}
                    </span>
                  </div>
                  {
                    item?.nftStatus === parseInt(process.env.NEXT_PUBLIC_ARTIFACT_PENDING) &&
                    <div className="mt-8 flex items-center justify-between">
                      <button
                        className="text-accent font-display text-sm font-semibold"
                        onClick={() => {
                          setShowModal(true);
                          setNftDetail(item);
                        }}
                      >
                        Mint Now
                      </button>
                    </div>
                  }
                </div>
              </article>
            );
          })}
        </div>
      }

      <MintModal
        showModal={showModal}
        setShowModal={setShowModal}
        title="Mint NFT"
        data={nftDetail}
        setLoading={setLoading}
        getNfthandler={getNfthandler}
      />
    </>
  );
};

export default CategoryItemProfile;
