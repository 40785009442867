import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import requestHandler from "./httpClient";

export const fetchArtifacts = createAsyncThunk("fetchArtifacts", async (wallet) => {
    let data = {
        walletAddress: wallet
    };
    try {
        const res = await requestHandler("artifact/artifactList", "post", data);
        return res;
    }
    catch (error) {
        throw error;
    }
});

const artifactSlice = createSlice({
    name: "getArtifact",
    initialState: {
        artifactLoading: false,
        artifactData: [],
        artifactError: false
    },
    extraReducers: (builder) => {
        builder.addCase(fetchArtifacts.pending, (state, action) => {
            state.artifactLoading = true;
            state.artifactError = false;
        })
        builder.addCase(fetchArtifacts.fulfilled, (state, action) => {
            state.artifactLoading = false;
            state.artifactError = false;
            state.artifactData = action.payload?.data?.data;
        })
        builder.addCase(fetchArtifacts.rejected, (state, action) => {
            state.artifactLoading = false;
            state.artifactError = true;
        })
    }
});

export default artifactSlice.reducer;
