import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "tippy.js/dist/tippy.css";
import Link from "next/link";
import Tippy from "@tippyjs/react";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { Skeleton } from "antd";

const loadingData = [1, 2, 3, 4];

const BidsCarousel = ({ artifactData, artifactLoading }) => {

  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar]}
        spaceBetween={30}
        slidesPerView="auto"
        loop={false}
        breakpoints={{
          240: {
            slidesPerView: 1,
          },
          565: {
            slidesPerView: 2,
          },
          1000: {
            slidesPerView: 3,
          },
          1100: {
            slidesPerView: 4,
          },
        }}
        navigation={{
          nextEl: ".bids-swiper-button-next",
          prevEl: ".bids-swiper-button-prev",
        }}
        className=" card-slider-4-columns !py-5"
      >
        {
          artifactLoading
            ?
            loadingData?.map((index) => {
              return <Skeleton.Image
                active
                key={index}
                style={{
                  width: 270,
                  height: 385,
                  objectFit: 'cover',
                  padding: 19,
                  marginRight: 20
                }} />
            })
            :
            artifactData?.map((item, index) => {
              // const { id, image, name: title, network: { name: networkName }, price: price } = item;
              return (
                <SwiperSlide className="text-white" key={index}>
                  <article>
                    <div className="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2xl block border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg text-jacarta-500">
                      <figure>
                        {/* {`item/${itemLink}`} */}
                        <Link
                          href={`/artifact/${item?.id}`}>
                          <img
                            src={`${process.env.NEXT_PUBLIC_AWS_URL}/${item?.image}`}
                            alt={item?.name}
                            height={230}
                            width={230}
                            className="rounded-[0.625rem] w-full lg:h-[230px] object-cover"
                            loading="lazy"
                          />
                        </Link>
                      </figure>
                      <div className="mt-4 flex items-center justify-between">
                        <Link href={`/artifact/${item?.id}`}>
                          <span className="font-display text-jacarta-700 hover:text-accent text-base dark:text-white">
                            {item?.name}
                          </span>
                        </Link>
                        <span className="dark:border-jacarta-600 border-jacarta-100 flex items-center whitespace-nowrap rounded-md border py-1 px-2">
                          <Tippy content={<span>{item?.network?.name?.toUpperCase()}</span>}>
                          </Tippy>

                          <span className="text-green text-sm font-medium tracking-tight">
                            {item?.price} {item?.collection?.network?.name?.toUpperCase()}
                          </span>
                        </span>
                      </div>
                      {/* <div className="mt-2 text-sm">
                    <span className="dark:text-jacarta-300 text-jacarta-500">
                      Current Bid
                    </span>
                    <span className="dark:text-jacarta-100 text-jacarta-700">
                      {bid_number} ETH
                    </span>
                  </div> */}

                      {/* <div className="mt-8 flex items-center justify-between">
                    <button
                      type="button"
                      className="text-accent font-display text-sm font-semibold"
                      onClick={() => dispatch(bidsModalShow())}
                    >
                      Place bid
                    </button>

                    <Likes
                      like={react_number}
                      classes="flex items-center space-x-1"
                    />
                  </div> */}
                    </div>
                  </article>
                </SwiperSlide>
              );
            })}
      </Swiper>
      {/* <!-- Slider Navigation --> */}
      <div className="group bids-swiper-button-prev swiper-button-prev shadow-white-volume absolute !top-1/2 !-left-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl sm:!-left-6 after:hidden">
        <MdKeyboardArrowLeft />
      </div>
      <div className="group bids-swiper-button-next swiper-button-next shadow-white-volume absolute !top-1/2 !-right-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl sm:!-right-6 after:hidden">
        <MdKeyboardArrowRight />
      </div>
    </>
  );
};

export default BidsCarousel;
