import Link from "next/link";
import React, { useState } from "react";
import { addNotification } from "../../components/Notification";
import { useMetaMask } from "metamask-react";
import { ethers } from "ethers";
import Contract from "../../context/NftMarketPlace.json";
import requestHandler from "../../redux/httpClient";

const MintModal = ({
  showModal,
  setShowModal,
  title,
  data,
  setLoading,
  getNfthandler
}) => {

  const [terms, setTerms] = useState(false);
  const { status, connect } = useMetaMask();

  const handleConnect = async () => {
    if (status === 'notConnected') {
      await connect();
    }
  };

  const uploadToIpfsHandler = async (id, image, price, contractAddress) => {
    setLoading(true);
    let data = {
      id: id,
      image: `${process.env.NEXT_PUBLIC_AWS_URL}/${image}`
    };
    try {
      const uploadToIpfsPayload = await requestHandler("artifact/uploadImageOnIpfs", "post", data, "", "file");
      await getNfthandler();
      if (uploadToIpfsPayload.status === 200) {
        await mintNftHandler(id, uploadToIpfsPayload?.data?.data, price, contractAddress);
        setShowModal(false);
      }
      setLoading(false);
    }
    catch (e) {
      await getNfthandler();
      setLoading(false);
    };
  };

  // const mintNftHandler = async (id, uri, price, contractAddress) => {
  //   setLoading(true);
  //   try {
  //     if (status === 'notInstalled' || status === 'unavailable') {
  //       const metaMaskUrl = 'https://metamask.io/download.html';
  //       if (window.confirm('MetaMask is not installed. Would you like to install it?')) {
  //         window.open(metaMaskUrl, '_blank');
  //       }
  //       setLoading(false);
  //     } else {
  //       await handleConnect();
  //       if (status === 'connected') {
  //         const provider = new ethers.BrowserProvider(window.ethereum);
  //         const signer = await provider.getSigner();
  //         const contract = new ethers.Contract(contractAddress, Contract.abi, signer);
  //         console.log('contract')
  //         try {
  //           console.log('listingPrice')
  //           const listingPrice = await contract.getListingPrice();
  //           console.log("listingPrice", listingPrice);
  //           const response = await contract.mintNFTWithId(id, uri, price);
  //           setLoading(false);
  //           if (response) {
  //             let data = {
  //               id: id,
  //               transactionHash: response.hash,
  //             };
  //             const mintPayload = await requestHandler("artifact/artifactMint", "post", data, "");
  //             if (mintPayload.status === 200) {
  //               addNotification({
  //                 title: "Success",
  //                 message: mintPayload?.data?.message,
  //                 type: "success",
  //               });
  //             }
  //             setShowModal(false);
  //             await getNfthandler();
  //           }
  //         }
  //         catch (error) {
  //           console.log(error)
  //           setLoading(false);
  //           addNotification({
  //             title: "Error",
  //             message: "Transaction canceled",
  //             type: "danger",
  //           });
  //           setShowModal(false);
  //         }
  //       }
  //       else {
  //         setShowModal(false);
  //         setLoading(false);
  //       }
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     setShowModal(false);
  //   }
  // };

  const mintNftHandler = async (id, uri, price, contractAddress) => {
    setLoading(true);
    try {
      if (status === 'notInstalled' || status === 'unavailable') {
        const metaMaskUrl = 'https://metamask.io/download.html';
        if (window.confirm('MetaMask is not installed. Would you like to install it?')) {
          window.open(metaMaskUrl, '_blank');
        }
        setLoading(false);
      } else {
        await handleConnect();
        if (status === 'connected') {
          try {
            const provider = new ethers.BrowserProvider(window.ethereum);
            const signer = await provider.getSigner();
            const contract = new ethers.Contract(contractAddress, Contract.abi, signer);

            const listingPrice = await contract.getListingPrice();
            const itemPrice = ethers.parseUnits(price.toString(), "ether");
            const lpValue = ethers.parseUnits(listingPrice.toString(), "wei");

            const transactionResponse = await contract.mintNFTWithId(id, uri, itemPrice, {
              value: lpValue,
            });

            setLoading(false);

            if (transactionResponse) {
              const data = {
                id: id,
                transactionHash: transactionResponse.hash,
              };

              const mintPayload = await requestHandler('artifact/artifactMint', 'post', data, '');
              if (mintPayload.status === 200) {
                addNotification({
                  title: 'Success',
                  message: mintPayload?.data?.message,
                  type: 'success',
                });
              }
              setTerms(false);
              setShowModal(false);
              await getNfthandler();
            }
          }
          catch (error) {
            setLoading(false);
            addNotification({
              title: 'Error',
              message: error.message || 'An unknown error occurred during minting.',
              type: 'danger',
            });
            setShowModal(false);
            setTerms(false);
          }

          // try {
          //   const transactionResponse = await contract.mintNFTWithId(id, uri, price, {
          //     value: lpValue,
          //   });
          //   console.log('Mint transaction response:', transactionResponse);

          //   setLoading(false);

          //   if (transactionResponse) {
          //     const data = {
          //       id: id,
          //       transactionHash: transactionResponse.hash,
          //     };

          //     const mintPayload = await requestHandler('artifact/artifactMint', 'post', data, '');
          //     if (mintPayload.status === 200) {
          //       addNotification({
          //         title: 'Success',
          //         message: mintPayload?.data?.message,
          //         type: 'success',
          //       });
          //     }

          //     setShowModal(false);
          //     await getNfthandler();
          //   }
          // } catch (error) {
          //   console.log('Error during minting:', error.message);
          //   setLoading(false);
          //   addNotification({
          //     title: 'Error',
          //     message: error.message || 'An unknown error occurred during minting.',
          //     type: 'danger',
          //   });
          //   setShowModal(false);
          // }
        } else {
          setShowModal(false);
          setLoading(false);
          setTerms(false);
        }
      }
    }
    catch (error) {
      setTerms(false);
      setLoading(false);
      setShowModal(false);
      addNotification({
        title: 'Error',
        message: error.message || 'An unknown error occurred.',
        type: 'danger',
      });
    }
  };


  return (
    showModal &&
    (
      <div className={showModal ? "modal fade show block" : "modal fade"}>
        <div className="modal-dialog max-w-2xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="buyNowModalLabel">
                {title}
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  setShowModal(false);
                  setTerms(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                </svg>
              </button>
            </div>

            <div className="modal-body p-6">
              <div className="mb-2 flex items-center justify-between">
                <span className="font-display text-jacarta-700 text-sm font-semibold dark:text-white">
                  Item
                </span>
                <span className="font-display text-jacarta-700 text-sm font-semibold dark:text-white">
                  Subtotal
                </span>
              </div>

              <div className="dark:border-jacarta-600 border-jacarta-100 relative flex items-center border-t border-b py-4">
                <figure className="mr-5 self-start">
                  <img
                    width={150}
                    height={150}
                    src={`${process.env.NEXT_PUBLIC_AWS_URL}/${data?.image}`}
                    alt={data?.name}
                    className="rounded-2lg"
                    loading="lazy"
                  />
                </figure>

                <div>
                  {/* <a href="collection.html" className="text-accent text-sm">
                    {data?.name}
                  </a> */}
                  <h3 className="font-display text-jacarta-700 mb-1 text-base font-semibold dark:text-white">
                    {data?.name}
                  </h3>
                  <div className="flex flex-wrap items-center">
                    <span className="dark:text-jacarta-300 text-jacarta-500 mr-1 block text-sm">
                      Creator Earnings: {data?.collection?.royalty}%
                    </span>
                    {/* <span data-tippy-content="The creator of this collection will receive 5% of the sale total from future sales of this item.">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        className="dark:fill-jacarta-300 fill-jacarta-700 h-4 w-4"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM11 7h2v2h-2V7zm0 4h2v6h-2v-6z" />
                      </svg>
                    </span> */}
                  </div>
                </div>

                {/* <div className="ml-auto">
                  <span className="mb-1 flex items-center whitespace-nowrap">
                    <span data-tippy-content="ETH">
                      <svg className="h-4 w-4">
                        <use xlinkHref="/icons.svg#icon-ETH"></use>
                      </svg>
                    </span>
                    <span className="dark:text-jacarta-100 text-sm font-medium tracking-tight">
                      1.55 ETH
                    </span>
                  </span>
                  <div className="dark:text-jacarta-300 text-right text-sm">
                    $130.82
                  </div>
                </div> */}
              </div>

              {/* <!-- Total --> */}
              <div className="dark:border-jacarta-600 border-jacarta-100 mb-2 flex items-center justify-between border-b py-2.5">
                <span className="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white">
                  Total
                </span>
                <div className="ml-auto">
                  <span className="flex items-center whitespace-nowrap">
                    {/* <span data-tippy-content="ETH">
                      <svg className="h-4 w-4">
                        <use xlinkHref="/icons.svg#icon-ETH"></use>
                      </svg>
                    </span> */}
                    <span className="text-green font-medium tracking-tight">
                      {data?.price} {data?.collection?.network?.name?.toUpperCase()}
                    </span>
                  </span>
                  {/* <div className="dark:text-jacarta-300 text-right">
                    $130.82
                  </div> */}
                </div>
              </div>

              {/* <!-- Terms --> */}
              <div className="mt-4 flex items-center space-x-2">
                <input
                  type="checkbox"
                  id="buyNowTerms"
                  className="checked:bg-accent dark:bg-jacarta-600 text-accent border-jacarta-200 focus:ring-accent/20 dark:border-jacarta-500 h-5 w-5 self-start rounded focus:ring-offset-0"
                  checked={terms}
                  onChange={() => { setTerms(!terms) }}
                />
                <label
                  htmlFor="buyNowTerms"
                  className="dark:text-jacarta-200 text-sm"
                >
                  By checking this box, I agree to {"Bitdenex's"}{" "}
                  <Link href="" className="text-accent">
                    Terms of Service
                  </Link>
                </label>
              </div>
            </div>
            {/* <!-- end body --> */}

            <div className="modal-footer">
              <div className="flex items-center justify-center space-x-4">
                {/* <Confirm_checkout /> */}

                <button
                  type="button"
                  className="bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
                  onClick={() => {
                    if (terms) {
                      if (data?.tokenUri == null || data?.tokenUri == undefined) {
                        uploadToIpfsHandler(data?.id, data?.image, data?.price, data?.collection?.contractAddress);
                      }
                      else {
                        mintNftHandler(data?.id, data?.tokenUri, data?.price, data?.collection?.contractAddress);
                      }
                    }
                    else {
                      addNotification({
                        title: "Alert",
                        message: "Please select terms and conditions",
                        type: "danger",
                      });
                    }
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  )
};

export default MintModal;
