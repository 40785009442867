import { configureStore } from '@reduxjs/toolkit';
// import counterReducer from '../features/counter/counterSlice';
import counterReducer from './counterSlice';
import dataCollectionDetailsReducer from './getCollections';
import dataCategoryDetailsReducer from './getCategory';
import artifactDetailsReducer from './getArtifacts';
import collectionDetails from './collectionDetails';
import getNetworks from './getNetworks';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
	key: 'root',
	version: 1,
	storage,
};

export const store = configureStore({
	reducer: {
		counter: counterReducer,
		getCollections: dataCollectionDetailsReducer,
		getCategory: dataCategoryDetailsReducer,
		getArtifact: artifactDetailsReducer,
		getCollectionDetail: collectionDetails,
		getNetworks: getNetworks,
	},
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});
