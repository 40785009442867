import React from "react";
import CategoryItemProfile from "./categoryItemProfile";

const Trending_categories_items = ({
  nftData,
  setLoading,
  getNfthandler,
  tranding_category_filter,
  filterVal,
  setFilterVal,
}) => {

  // const handleFilter = (id) => {
  //   if (id === parseInt(process.env.NEXT_PUBLIC_ARTIFACT_PENDING)) {
  //     const filtered = nftData.filter(item =>
  //       item.nftStatus === parseInt(process.env.NEXT_PUBLIC_ARTIFACT_PENDING)
  //     );
  //     setFilteredData(filtered);
  //   }
  //   else if (id === parseInt(process.env.NEXT_PUBLIC_ARTIFACT_MINTED)) {
  //     const filtered = nftData.filter(item =>
  //       item.nftStatus === parseInt(process.env.NEXT_PUBLIC_ARTIFACT_MINTED)
  //     );
  //     setFilteredData(filtered);
  //   }
  //   else if (id === parseInt(process.env.NEXT_PUBLIC_ARTIFACT_COMPLETE)) {
  //     const filtered = nftData.filter(item =>
  //       item.nftStatus === parseInt(process.env.NEXT_PUBLIC_ARTIFACT_COMPLETE)
  //     );
  //     setFilteredData(filtered);
  //   }
  //   else if (id === parseInt(process.env.NEXT_PUBLIC_ARTIFACT_DECLINE)) {
  //     const filtered = nftData.filter(item =>
  //       item.nftStatus === parseInt(process.env.NEXT_PUBLIC_ARTIFACT_DECLINE)
  //     );
  //     setFilteredData(filtered);
  //   }
  //   else if (id === parseInt(process.env.NEXT_PUBLIC_ARTIFACT_OWNED)) {
  //     const filtered = nftData.filter(item =>
  //       item.nftStatus === parseInt(process.env.NEXT_PUBLIC_ARTIFACT_OWNED)
  //     );
  //     setFilteredData(filtered);
  //   }
  // };

  // const handleFilter = (id) => {
  //   const filtered = nftData.filter(item => item.nftStatus === id);
  //   setFilteredData(filtered);
  // };

  return (
    <>
      <div className="mb-8 flex flex-wrap items-center justify-between">
        <ul className="flex flex-wrap items-center">
          {tranding_category_filter.map(({ id, svg, text }) => (
            <li className="my-1 mr-2.5" key={id}>
              <button
                onClick={() => {
                  setFilterVal(id);
                }}
              >
                <div
                  className={
                    filterVal === id
                      ? "dark:border-jacarta-600 group bg-accent border-jacarta-100 font-display flex h-9 items-center rounded-lg border px-4 text-sm font-semibold transition-colors border-transparent text-white dark:border-transparent capitalize"
                      : "dark:border-jacarta-600 dark:bg-jacarta-900 dark:hover:bg-accent group hover:bg-accent border-jacarta-100 font-display text-jacarta-500 flex h-9 items-center rounded-lg border bg-white px-4 text-sm font-semibold transition-colors hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent dark:hover:text-white capitalize"
                  }
                >
                  <svg
                    className={
                      filterVal === id
                        ? "icon mr-1 h-4 w-4 transition-colors fill-white"
                        : "icon fill-jacarta-700 dark:fill-jacarta-100 mr-1 h-4 w-4 transition-colors group-hover:fill-white"
                    }
                  >
                    <use xlinkHref={`/icons.svg#icon-${svg}`}></use>
                  </svg>
                  <span>{text}</span>
                </div>
              </button>
            </li>
          ))}
        </ul>
      </div>

      <CategoryItemProfile
        nftData={nftData}
        setLoading={setLoading}
        getNfthandler={getNfthandler}
      />
    </>
  );
};

export default Trending_categories_items;
